@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 95%;
    --foreground: 210 8% 5%;

    --card: 240 8% 97%;
    --card-foreground: 210 8% 5%;

    --popover: 240 8% 97%;
    --popover-foreground: 210 8% 5%;

    --primary: 202 42% 46%;
    --primary-foreground: 0 0% 98%;

    --secondary: 180 6% 93%;
    --secondary-foreground: 210 8% 5%;

    --muted: 220 10% 92%;
    --muted-foreground: 220 16% 40%;

    --accent: 234 89% 73%;
    --accent-foreground: 220 16% 20%;

    --destructive: 354 42% 56%;
    --destructive-foreground: 220 16% 98%;

    --border: 220 16% 85%;
    --input: 220 16% 85%;
    --ring: 213 32% 52%;

    --radius: 0.75rem;
  }

  .dark {
    --background: 220 7% 8%;
    --foreground: 180 9% 98%;

    --card: 220 6% 10%;
    --card-foreground: 180 9% 98%;

    --popover: 220 6% 10%;
    --popover-foreground: 180 9% 98%;

    --primary: 213 32% 62%;
    --primary-foreground: 240 9% 2%;

    --secondary: 210 8% 19%;
    --secondary-foreground: 180 9% 98%;

    --muted: 240 3.7% 15.9%;
    --muted-foreground: 220 16% 70%;

    --accent: 234.45 89.47% 73.92%;
    --accent-foreground: 220 16% 16%;

    --destructive: 354 42% 56%;
    --destructive-foreground: 220 16% 98%;

    --border: 220 16% 25%;
    --input: 220 16% 25%;
    --ring: 213 32% 62%;
  }
}



@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}
